<template>
  <div fluid :class="userEmail ? '' : 'mx-4'" class="pa-0">
    <Reports
      ref="reports"
      :companyView="companyView"
      :headers="headers"
      :records="records"
      :loadingLogs="loadingLogs"
      @tableOptions="tableOptions = $event"
      :showDataTable="showDataTable"
      @showDataTable="showDataTable = $event"
    >
      <template v-slot:table-title>
        <span class="mr-5">
          {{ $ml.get(titleKey) }}
        </span>

        <download-csv
          v-if="showDataTable"
          ref="downloadCsv"
          :data="records"
          :prepareData="prepareData"
          :fileName="csvFileName || 'Logs'"
          :prevent="true"
        >
          <v-btn
            text
            class="text-none text-body-2 font-weight-bold mr-2 my-2"
            color="accent"
            :loading="loadingLogs"
            @click.stop="downloadCsv"
          >
            <svg
              style="width: 24px; height: 24px"
              viewBox="0 0 24 24"
              class="mr-2"
            >
              <path
                fill="currentColor"
                d="M2 12H4V17H20V12H22V17C22 18.11 21.11 19 20 19H4C2.9 19 2 18.11 2 17V12M12 15L17.55 9.54L16.13 8.13L13 11.25V2H11V11.25L7.88 8.13L6.46 9.55L12 15Z"
              />
            </svg>
            {{ $ml.get("download_csv") }}
          </v-btn>
        </download-csv>
        <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
        <v-tooltip v-if="showDataTable" bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              icon
              class="mr-2"
              color="secondary"
              v-on="{ ...tooltip }"
              @click.stop="getPaginatedLogs"
              :loading="loadingLogs"
            >
              <v-icon v-text="'mdi-reload'" />
            </v-btn>
          </template>
          <span>{{ $ml.get("update") }}</span>
        </v-tooltip>
        <v-select
          v-if="showDataTable"
          class="mb-1 mr-4"
          :disabled="loadingLogs"
          v-model="optionSelected"
          :items="selectOptions"
          return-object
          dense
          outlined
          :multiple="false"
          style="max-width: 220px; height: 35px"
          @click.stop=""
        >
          <template v-slot:selection="{ item }">
            {{ $ml.get(item.text) }}
          </template>
          <template v-slot:item="{ item }">
            {{ $ml.get(item.text) }}
          </template>
          <template v-slot:prepend-inner>
            <v-icon v-text="'mdi-calendar-month'" class="mr-2" />
          </template>
        </v-select>
        <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
      </template>
    </Reports>
  </div>
</template>

<script>
import JsonCSV from "@/components/main/shared/Download.vue";
import Reports from "@/components/logs/Reports.vue";
import { subtractDays, subtractMonths } from "@/helpers/utils";
import { backendLogCodes, loginAttemptCodes } from "@/helpers/variables";
import { mapActions } from "vuex";

export default {
  name: "ReportsPaginated",

  components: { "download-csv": JsonCSV, Reports },

  props: {
    companyView: { type: Boolean },
    titleKey: { type: String, default: () => "reports" },
    userEmail: { type: String },
    getterUrl: { type: String },
    formatter: { type: Function },
    headers: { type: Array },
    prepareData: { type: Function },
    contexts: { type: Array, default: () => [] },
  },

  data() {
    return {
      loadingLogs: null,
      tableOptions: null,
      showDataTable: null,
      records: [],
      logControl: {
        count: 0,
        totalRows: 0,
        page: 1,
        totalPages: 0,
      },
      logsLimit: 1000,
      defaultlogsLimit: 1000,
      csvlogsLimit: 5000,
      selectOptions: [
        { text: "last_seven_days", value: subtractDays(7) },
        { text: "last_three_months", value: subtractMonths(3) },
        { text: "last_six_months", value: subtractMonths(6) },
      ],
      optionSelected: { text: "last_seven_days", value: subtractDays(7) },
    };
  },

  computed: {
    hasMoreLogsToLoad() {
      return this.logControl.count < this.logControl.totalRows;
    },
    shouldLoadMore() {
      const totalPages = Math.ceil(
        this.records.length / this.tableOptions.itemsPerPage
      );
      return totalPages == this.tableOptions.page;
    },
    url() {
      const from_date = `from_date=${this.optionSelected.value}`;
      const email = this.userEmail ? `&email=${this.userEmail}` : "";
      const page_index = `&page_index=${this.logControl.page}`;
      const rows_per_page = `&rows_per_page=${this.logsLimit}`;
      const contexts = `&contexts=${this.contexts.join()}`;

      return `${
        this.getterUrl
      }?${from_date}${email}${page_index}${rows_per_page}${
        this.contexts.length ? contexts : ""
      }`;
    },
    csvFileName() {
      let csvFileName = "";
      if (this.titleKey) {
        csvFileName += this.$ml.get(this.titleKey).replace(/\s/g, "_");
      }
      if (this.titleKey && this.userEmail) {
        csvFileName += "_";
      }
      if (this.userEmail) {
        csvFileName += this.userEmail.split("@")[0];
      }
      return csvFileName;
    },
  },

  watch: {
    async optionSelected() {
      if (this.optionSelected.text !== "last_seven_days") {
        this.logsLimit = this.csvlogsLimit;
      } else {
        this.logsLimit = this.defaultlogsLimit;
      }
      await this.getPaginatedLogs();
    },
    tableOptions() {
      if (this.hasMoreLogsToLoad && this.shouldLoadMore) {
        this.getPaginatedLogs(false);
      }
    },
    getterUrl: {
      handler() {
        this.getPaginatedLogs();
      },
      immediate: true,
    },
    showDataTable: {
      handler() {
        if (this.loadingLogs === null) this.getPaginatedLogs();
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["setSnackbarDefault"]),

    getEventDescription(eventCode) {
      if (loginAttemptCodes.includes(eventCode)) {
        return this.$ml.get("login_attempt");
      } else if (eventCode === backendLogCodes.ADD_USERS_TO_GROUP) {
        return this.$ml.get("add_users_to_group");
      } else if (eventCode === backendLogCodes.REMOVE_USERS_FROM_GROUP) {
        return this.$ml.get("remove_users_from_group");
      } else if (eventCode === backendLogCodes.CHANGE_USER_PASSWORD) {
        return this.$ml.get("user_password_change");
      } else if (eventCode === backendLogCodes.CHANGE_OWN_PASSWORD) {
        return this.$ml.get("user_password_change");
      } else if (eventCode === backendLogCodes.ADD_VACATION_TO_USER) {
        return this.$ml.get("vacation_period");
      } else if (eventCode === backendLogCodes.REMOVE_VACATION_FROM_USER) {
        return this.$ml.get("vacation_period");
      } else if (eventCode === backendLogCodes.ADD_GROUPS_TO_USER) {
        return this.$ml.get("add_groups_to_user");
      } else if (eventCode === backendLogCodes.REMOVE_GROUPS_FROM_USER) {
        return this.$ml.get("remove_groups_from_user");
      } else if (eventCode === backendLogCodes.RECEIVE_USER_SINGLE_ACCESS) {
        return this.$ml.get("receive_user_single_access");
      } else if (eventCode === backendLogCodes.LOST_USER_SINGLE_ACCESS) {
        return this.$ml.get("removed_single_access_from");
      } else if (eventCode === backendLogCodes.ACTIVATE_SSO) {
        return this.$ml.get("user_activate_sso_event");
      } else if (eventCode === backendLogCodes.DISABLE_SSO) {
        return this.$ml.get("user_disable_sso_event");
      } else if (eventCode === backendLogCodes.ADDED_GROUP_RULES) {
        return this.$ml.get("user_added_group_rules");
      } else if (eventCode === backendLogCodes.REMOVED_GROUP_RULES) {
        return this.$ml.get("user_removed_group_rules");
      } else if (eventCode === backendLogCodes.DELETED_GROUPS) {
        return this.$ml.get("user_deleted_groups");
      } else if (eventCode === backendLogCodes.UPDATED_GROUP_RULES) {
        return this.$ml.get("user_updated_group_rules");
      } else if (eventCode === backendLogCodes.ADD_ADMIN_PERMISSION) {
        return this.$ml.get("user_added_manager_permission");
      } else if (eventCode === backendLogCodes.REMOVE_ADMIN_PERMISSION) {
        return this.$ml.get("user_removed_manager_permission");
      } else if (eventCode === backendLogCodes.CUSTOMIZE_LOGIN) {
        return this.$ml.get("customize_login_label");
      } else if (
        [
          backendLogCodes.CANCEL_ACTIVATION_SSO_ASYNC,
          backendLogCodes.ACTIVATE_SSO_ASYNC,
        ].includes(eventCode)
      ) {
        return this.$ml.get("automatic_activation");
      }
      eventCode = eventCode.toLowerCase();
      return this.$ml.get(eventCode);
    },

    formatLog(item) {
      return {
        ...item,
        event: this.getEventDescription(item.status.code),
        date: new Date(item.created_at),
        author_user: item.status.author_user || item.status.logged_out_user,
        ip: item.status.ip,
        browser: item.status.browser,
        operational_system: item.status.operational_system,
      };
    },

    getLogs() {
      return this.$axios.get(this.url);
    },

    async getPaginatedLogs(substitute = true) {
      if (this.showDataTable) {
        this.loadingLogs = true;
        let response, error;
        const control = this.logControl;
        if (substitute || control.page != control.totalPages) {
          if (!substitute && control.page < control.totalPages) {
            control.page++;
          } else {
            control.page = 1;
          }
          try {
            response = await this.getLogs();
          } catch (e) {
            error = e;
            this.setSnackbarDefault({
              message: "error_user_logs",
              color: "error",
            });
          }
          if (!error && response.data) {
            this.responseHandler(response, substitute);
          }
        }
        if (this.hasMoreLogsToLoad && this.shouldLoadMore) {
          await this.getPaginatedLogs(false);
        }
        this.loadingLogs = false;
      }
    },

    responseHandler(response, substitute = true) {
      const logs = response.data.logs.map(this.formatter || this.formatLog);
      const totalRows = response.data.total_rows;

      const control = this.logControl;

      control.totalRows = totalRows;
      control.totalPages = Math.ceil(totalRows / this.logsLimit);
      this.records = substitute ? logs : this.records.concat(logs);
      control.count = this.records.length;
      const page = Math.ceil(control.count / this.logsLimit);
      control.page = page < 1 ? 1 : page;
    },

    async downloadCsv() {
      while (this.hasMoreLogsToLoad) {
        await this.getPaginatedLogs(false);
      }
      this.$refs.downloadCsv.generate();
    },
  },

  beforeMount() {
    this.showDataTable = this.companyView;
  },
};
</script>
